import styled, { css } from 'styled-components';
import { AddCalenderButton } from '../MainEvents/styles';

const RegularFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Bold';

const CommentsContainer = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  font-family: ${RegularFont};
  
  > span {
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(0,0,0);
  }
`;

const CommentContent = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  margin-top: 15px;
  
  ${(props) => props.isReply && css`
    padding: 0 0 0 70px;

    @media (max-width: 500px) {
      padding: 0 0 0 50px;
    }
  `}
`;

const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  float: left;
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  > img {
    width: 100%;
    height: 100%;
    float: left;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
  }
`;

const CommentSection = styled.div`
  display: inline-block;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: calc(100% - 70px);
  padding: 15px;
  box-shadow: 0.5px 0.866px 32px rgba(0,0,0,0.17);
  border-radius: 8px;
  white-space: pre-line;
  > img{
    width: 300px;
  }
  span {
    font-size: 16px;
    letter-spacing: 0px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    pointer-events: none;
  }
  
  span:first-child {
    font-family: ${BoldFont};
    text-transform: capitalize;
    span {
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: Rubik-Bold;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      pointer-events: auto;
      cursor: pointer;
    }
  }
  
  > div {
    display: inline-block;
    color: #159fc9;
    margin-top: -3px;
    
    @media (max-width: 500px) {
      margin-top: -5px;
    }
  }

  .CommentDescription {
    display: block;
    font-size: 16px;
    width: 100%;
    word-break: break-word;
    text-transform: none !important;
    font-family: ${RegularFont} !important;
    text-align: justify;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  .CommentSpan {
    display: block;
  }
  
  ${({ isReply }) => isReply && css`
    padding: 10px;
  `}
  
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    padding: 10px;
  }
`;

const StyledLink = styled.a`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 17px;
  color: #0D4270;
  font-size: 14px;
  font-family: 'Rubik';
  width: 200px;
  margin-left:70px;
  >div{
    width: auto;
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #0D4270;
    font-size: 14px;
    padding: 0 5px;
    font-family: 'Rubik';
  }

  > img {
    width: 18px;
    margin-right: 5px;
  }
  
  ${({isReply}) => isReply && css`
    margin-left: 0;
  `}
  
  &:focus, &:hover {
    outline: none;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    margin-left: 50px;
    font-size: 12px;
  }
`;

const TimeContent = styled.span`
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  text-align: right;
  margin-top: 10px;
  display: inline-block;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 107px)'};
  
  ${({ isReply }) => isReply && css`
    width: 100%;
    margin-right: 5px;
  `}
  
  @media (max-width: 500px) {
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 82px)'};
    font-size: 10px;

    ${({ isReply }) => isReply && css`
      width: 100%;
    `}
  }
`;

const ViewMoreLink = styled.a`
  font-size: 14px;
  color: #159fc9;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  
  ${({ isReply }) => isReply && css`
    padding-left: 70px;
    
    @media (max-width: 500px) {
      padding-left: 0;
    }
  `}
  
  &:focus, &:hover {
    outline: none;
    color: #159fc9;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const TextFieldContainer = styled.div`
  padding-bottom: 10px;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const SingleComment = styled.div`
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
`;

const ButtonContainer = styled.div`
  text-align: right;
  width: 100%;
`;

const PostCommentButton = styled(AddCalenderButton)`
  margin-top: 15px;
  margin-left:70px;
  float: left;
  height: 40px;
  width: 103px;
  padding: 0;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border-radius: 6px;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */
color: #FFFFFF;
>img{
  margin-left:10px;
}
`;

export { CommentsContainer, CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink,
  TextFieldContainer, SingleComment, ButtonContainer, PostCommentButton, ImageWrapper };