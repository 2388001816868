/* eslint-disable camelcase */
import React, { Component } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink, CommentsContainer, TextFieldContainer, SingleComment,
  ButtonContainer, PostCommentButton, ImageWrapper } from './styles';
import { FieldContainer } from '../EventDetails/styles';
import { InputFieldContainer, ShoutoutImage } from '../SocialFeeds/styles';
import Image from '../Image';
import SocialFeedTextarea from '../SocialFeeds/SocialFeedTextarea'
import { convertDateInTimezone, convertUnicode, applyHighlights, getDecodedString, convertMonthFromDate } from '../../utils/methods';
import { getUserName, getUserProfileImage, getUserId, getUserPosts } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

class Comments extends Component {
  constructor() {
    super();
    this.state = {
      replyTextField: '',
      replyText: '',
      replyCount: 3,
      isUpdatedImage: false,
      mentionedUsers: [],
      users: [],
      showGivenShoutout: false,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: 0,
      exemplifyID: null
    }
  }

  componentDidMount() {
    if(!this.props.userName) this.props.fetchUserName();
    if(!this.props.profileImage) this.props.fetchUserProfileImage();
    if(!this.props.userId) this.props.fetchUserId();
    this.checkBase64(this.props.profileImage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.replySuccess) {
      this.setState({
        replyText: '',
        replyTextField: ''
      });
    }
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  checkBase64(profileImage) {
    if(profileImage) {
      let arr = profileImage.split('/');
      if(arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  onChangeValue = (e) => {
    this.setState({
      replyText: e.target.value
    });
  };

  addReply = (e, commentId) => {
    e.preventDefault();
    const { users, userIdArray, replyText, taggedUser, mentionedUsers, exemplifyID } = this.state;
    const { postReply, id } = this.props;

    let shoutout = {
      exemplifies: mentionedUsers
    }

    let reply =replyText, shoutOutUserName;
    if(!_.isEmpty(mentionedUsers)) {
      shoutOutUserName = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    let image = "";
    postReply(commentId, reply, id, shoutout, shoutOutUserName, exemplify_id, image);

    this.setState({
      showTextAreaFor: null,
      reply: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      thankYou: [],
      congrats: [],
      jobWellDone: [],
      taggedUser: '',
      userIdArray: [],
      users: []
    });
  };

  showTextField = (id) => {
    this.setState({
      replyTextField: id
    })
  };

  showMoreReplies = (replyCount) => {
    this.setState({
      replyCount
    });
  };

  showProfileImage = (comment, isOwn) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if(isOwn) {
      if(isUpdatedImage) {
        return (<img src={profileImage} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if(userId === comment.uid) {
      if(isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" />)
      }
      else {
        return (<Image image={profileImage} alt="avatar" />)
      }
    }
    else {
      return (<Image image={comment.profile_image} alt="avatar" />)
    }
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if(userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if(!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else 
        taggedUsers = taggedUserName;
    }
    this.setState({
      replyText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if(userIdArray.length !== 0) {
      if(userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else 
        return true;
    } else if(value && value.trim() !== ''){
      return false;
    }
    return true;
  };

  renderTextArea = (value, id) => {
    const { noFloat, enableTrigger } = this.props;
    return(
      <TextFieldContainer noFloat={noFloat}>
        <CommentContent noFloat={noFloat}>
          <ImageWrapper>
            {this.showProfileImage('', true)}
          </ImageWrapper>
          <InputFieldContainer comment>
            <SocialFeedTextarea
              userCompany={this.props.userCompany}
              addInspiration= {this.onChangeValue}
              inspirationQuote={value}
              updateText={this.updateText}
              users={this.state.users}
              userIdArray={this.state.userIdArray}
              placeholder={this.props.t("Write your Reply here...")}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
            />
          </InputFieldContainer>
        </CommentContent>
        <ButtonContainer noFloat={noFloat}>
          <PostCommentButton color="#159fc9" onClick={(e) => this.addReply(e, id)} disabled={this.togglePostButton(value)}>{this.props.t("Reply")}<img src='/public/images/SendPost.svg'/></PostCommentButton>
        </ButtonContainer>
      </TextFieldContainer>
    );
  };

  getTaggedUserId = (e) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(e.target.id, history);
    if(e.target.id){
      if(userId === e.target.id) {
        history.push('/profile');
      } else {
        history.push(`/profile/${e.target.id}`);
      }
    }
  };

  showComment(comment) {
    const cleanHTML = DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment).split('\\n').join('<br />')));
    return (
      <span className="CommentDescription">{cleanHTML}</span>
    );
    // return (<span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment).split('\\n').join('<br />')}} />)
  }

  showImage(image) {
    return (<img src={`${ImageUrl}/${image}`} />)
  }

  render() {
    const { comments, fixHeight, noFloat, userId } = this.props;
    const { replyTextField, replyText, replyCount } = this.state;

    return (
      <CommentsContainer noFloat={noFloat}>
        <FieldContainer fixHeight={fixHeight} commentsSection noFloat={noFloat}>
          {
            comments.map((comment, index) => {
              const TimeOfComment = convertMonthFromDate(moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMMM DD'), this.props);
              
              const chunkOfReply = (!_.isEmpty(comment.replies) ? comment.replies.slice(0, replyCount) : []);
              return (
                <SingleComment key={index} noFloat={noFloat}>
                  <CommentContent noFloat={noFloat}>
                    <ImageWrapper borderRadius={"3px"}>
                      {this.showProfileImage(comment, false)}
                    </ImageWrapper>
                    <CommentSection noFloat={noFloat}>
                      <span className="CommentSpan">{comment.uid === userId ? this.props.t('You') : comment.fullname}&nbsp;</span>
                      {/*<div>.</div>*/}
                      { !this.props.isChallengeOrEvent ?
                        <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                          { !_.isNull(comment.shoutout_user_name) ?
                            // <span dangerouslySetInnerHTML={{__html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, ""))}}/> : null
                            <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(comment.shoutout_user_name.replace(/￼/g, ""))))}</span> : null
                          }
                          { comment.recognition_image !== null ? 
                            <ShoutoutImage comment>
                              <Image image={comment.recognition_image}/>
                            </ShoutoutImage> : null
                          }
                          {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment.comment).split('\\n').join('<br />')}} /> */}
                          <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                        </span> :
                        comment.image ? [this.showImage(comment.image), this.showComment(comment.comment)] : this.showComment(comment.comment)
                      }
                    </CommentSection>
                    {replyTextField !== comment.id && <StyledLink noFloat={noFloat} onClick={() => this.showTextField(comment.id)}>{this.props.t("Reply")}</StyledLink>}
                    <TimeContent fullWidth={replyTextField === comment.id}>{TimeOfComment}</TimeContent>
                    {replyTextField === comment.id && this.renderTextArea(replyText, comment.id )}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = convertMonthFromDate(moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMMM DD'), this.props);
                        return(
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <ImageWrapper>
                              {this.showProfileImage(reply, false)}
                            </ImageWrapper>
                            <CommentSection isReply noFloat={noFloat}>
                              <span>{reply.uid === userId ? 'You' : reply.fullname}&nbsp;</span>
                              {/*<div>.</div>*/}
                              { !this.props.isChallengeOrEvent ?
                                <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                                  { !_.isNull(reply.shoutout_user_name) ? 
                                    // <span dangerouslySetInnerHTML={{__html: convertUnicode(reply.shoutout_user_name)}}/> : null
                                    <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name)))}</span> : null
                                  }
                                  { reply.recognition_image !== null ? 
                                    <ShoutoutImage comment>
                                      <Image image={reply.recognition_image}/>
                                    </ShoutoutImage> : null
                                  }
                                  {/* <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} /> */}
                                  <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                                </span> : 
                                // <span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(reply.reply).split('\\n').join('<br />')}} />
                                <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                              }
                            </CommentSection>
                            <TimeContent isReply>{TimeOfReply}</TimeContent>
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&
                        <ViewMoreLink isReply onClick={() => this.showMoreReplies(comment.replies.length)}>
                          View More Replies
                        </ViewMoreLink>
                    }
                  </CommentContent>
                </SingleComment>
              )
            })
          }
        </FieldContainer>
      </CommentsContainer>
    )
  }
}

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  fixHeight: PropTypes.bool,
  postReply: PropTypes.func,
  id: PropTypes.number,
  replySuccess: PropTypes.string,
  noFloat: PropTypes.bool,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  history: PropTypes.object,
  userCompany: PropTypes.object,
  isChallengeOrEvent: PropTypes.bool,
  enableTrigger: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  userName: state.profileData.userName,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserName: () => dispatch(getUserName()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history))
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(Comments));
